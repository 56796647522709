
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonButton, toastController, IonSpinner, IonLabel, IonInput, IonItem, IonGrid, IonToggle, IonCard, IonCardHeader, IonCardSubtitle, IonIcon } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import { cloudDoneOutline, cloudDoneSharp, arrowBackOutline, arrowBackSharp } from 'ionicons/icons';


export default defineComponent({
  name: 'InseratActivateAutomaticRenewal',
  data () {
    return {
      doneIconIos: cloudDoneOutline,
      doneIconMd: cloudDoneSharp,
      backIconIos: arrowBackOutline,
      backIconMd: arrowBackSharp,
      finishedLoading: true,
      doneUploading: false,
      errorWhileLoading: false,
      isAutomaticRenewal: false,
      countOfDays: 1,
      inserat: null,

    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonButton,
    IonSpinner,
    IonLabel,
    IonInput,
    IonItem,
    IonGrid,
    IonToggle,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonIcon
  },
  created() {

    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){
      // ready to create inserat
    } else {
      // push to main
      this.$router.push({ path: '/login' });
    }

    // check user permission
    if (!localStorage.userRoles.includes('ROLE_STAR')){
      this.$router.push({ path: '/login' });
    }

    // check if route param is available
    if (!this.$route.params.id){
      this.$router.push({ path: '/profile/inserats' });
    }

    // get inserat data
    axios.get(process.env.VUE_APP_API_URL + '/inserats/' + this.$route.params.id, {
      headers: {
        'Authorization': `Bearer ` + localStorage.userToken
      }
    })
        .then(response => {

          this.inserat = response.data;

          if (this.inserat.isActive){
              this.openToast("Dein Inserat muss offline sein, damit du es bearbeiten kannst.", 4000);
              this.errorWhileLoading = true;
          }

          this.pathToInseratEdit = "/inserat/" + this.inserat.uuid + "/edit";

          if (this.inserat.automaticIncreaseTime){
            this.isAutomaticRenewal = this.inserat.automaticIncreaseTime;
            this.countOfDays = this.inserat.addDays ? this.inserat.addDays : 0;
          }

        })
        .catch(error => {
          this.errorWhileLoading = true;
          console.log(error);
        })
        .finally(() => {
          this.finishedLoading = true;
        });


  },
  methods: {
    getRoute() {
      return "/inserat/" + this.$route.params.id + "/edit";
    },
     isNumeric(str) {
      return !isNaN(str) && !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    removeAllLetters(){
       // reset to origin value if set
      if (this.inserat.automaticIncreaseTime){
        this.countOfDays = this.inserat.addDays;
      } else {
        this.countOfDays = '1';
      }
    },
    update(){

      // check if countOfDays is a number
      if (this.isAutomaticRenewal){
        if (!this.isNumeric(this.countOfDays)){
          this.removeAllLetters();
          this.openToast("Bitte nur ganze Zahlen in das Feld eintragen.", 5000);
          return;
        }
      }

      if (this.isAutomaticRenewal && (parseInt(this.countOfDays) < 1 || parseInt(this.countOfDays) > 10)) {
          this.openToast("Überprüfe deine Eingaben. Dein Wert darf nicht mehr als 10 sein und nicht weniger als 1.", 5000);
          return;
      }


      const inseratData = {
        'automaticIncreaseTime': this.isAutomaticRenewal,
        'addDays': parseInt(this.countOfDays),
        'fields': true
      };

      if (!this.isAutomaticRenewal){
          inseratData['addDays'] = null;
      }

      // patch inserat updates
      this.finishedUploading = false;
      axios.patch(process.env.VUE_APP_API_URL + '/inserats/' + this.inserat.uuid, inseratData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken,
          'Content-Type' : 'application/merge-patch+json',
        }
      })
          .then(response => {
            console.log(response);
            this.doneUploading = true;
          })
          .catch(error => {
            this.errorWhileCreating = true;
          }).finally(() => {
            this.finishedUploading = true;
            //this.$router.push({ path: this.pathToInseratEdit});
      });

    },
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    }
  }
});

