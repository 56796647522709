<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Automatische Verlängerung</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" v-if="finishedLoading">
      <ion-row class="sideMargins topMargins">
        <ion-col size="12">
          <ion-button size="small" style="margin-left: 16px; margin-bottom: 25px;" :router-link="getRoute()"><ion-icon :ios="backIconIos" :md="backIconMd"></ion-icon> Abbrechen</ion-button>
        </ion-col>
        <ion-col size="12" style="margin-top: -40px;">
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle>Hinweis</ion-card-subtitle>
              <small>Du kannst diese Option jederzeit deaktivieren.</small>
            </ion-card-header>
          </ion-card>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-grid>
              <ion-row>
                <ion-col size="3">
                  <ion-toggle v-model="isAutomaticRenewal" style="margin-bottom: 10px;"></ion-toggle>
                </ion-col>
                <ion-col size="9">
                  <ion-label class="ion-text-wrap">Soll die automatische Verlängerung aktiviert sein?</ion-label>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </ion-col>
        <ion-col size="12" v-if="isAutomaticRenewal">
          <ion-item>
            <ion-label position="stacked">Anzahl Tage</ion-label>
            <small>Minimum 1 / Maximum 10</small>
            <ion-input v-model="countOfDays"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-button color="danger" style="width: 100%; margin-top: 25px; margin-bottom: 25px;" @click="update">Speichern</ion-button>
        </ion-col>
      </ion-row>
    </ion-content>

    <ion-content v-if="!finishedLoading" class="spinnerCenter">
      <ion-spinner name="dots"></ion-spinner>
    </ion-content>

    <ion-content v-if="doneUploading" :fullscreen="true">
      <div class="container sideMargins">
        <ion-icon :ios="doneIconIos" :md="doneIconMd" style="width: 100px; height: 100px;"></ion-icon><br><br><br>
        <strong class="capitalize">Deine Änderungen wurden gespeichert</strong>
        <p><br>Du kannst diese Option jederzeit verändern.</p>
        <ion-button size="large" class="topMargins sideMargins" :href="pathToInseratEdit" expand="block" color="primary">Fertig</ion-button>
      </div>
    </ion-content>

    <ion-content :fullscreen="true" v-if="errorWhileLoading">
      <div class="container">
        <strong class="capitalize">Fehler</strong>
        <p>Versuch es noch einmal in ein paar Minuten.</p>
        <ion-button size="large" class="topMargins sideMargins" :href="pathToInseratEdit" expand="block" color="primary">Später versuchen</ion-button>
      </div>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonButton, toastController, IonSpinner, IonLabel, IonInput, IonItem, IonGrid, IonToggle, IonCard, IonCardHeader, IonCardSubtitle, IonIcon } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import { cloudDoneOutline, cloudDoneSharp, arrowBackOutline, arrowBackSharp } from 'ionicons/icons';


export default defineComponent({
  name: 'InseratActivateAutomaticRenewal',
  data () {
    return {
      doneIconIos: cloudDoneOutline,
      doneIconMd: cloudDoneSharp,
      backIconIos: arrowBackOutline,
      backIconMd: arrowBackSharp,
      finishedLoading: true,
      doneUploading: false,
      errorWhileLoading: false,
      isAutomaticRenewal: false,
      countOfDays: 1,
      inserat: null,

    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonButton,
    IonSpinner,
    IonLabel,
    IonInput,
    IonItem,
    IonGrid,
    IonToggle,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonIcon
  },
  created() {

    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){
      // ready to create inserat
    } else {
      // push to main
      this.$router.push({ path: '/login' });
    }

    // check user permission
    if (!localStorage.userRoles.includes('ROLE_STAR')){
      this.$router.push({ path: '/login' });
    }

    // check if route param is available
    if (!this.$route.params.id){
      this.$router.push({ path: '/profile/inserats' });
    }

    // get inserat data
    axios.get(process.env.VUE_APP_API_URL + '/inserats/' + this.$route.params.id, {
      headers: {
        'Authorization': `Bearer ` + localStorage.userToken
      }
    })
        .then(response => {

          this.inserat = response.data;

          if (this.inserat.isActive){
              this.openToast("Dein Inserat muss offline sein, damit du es bearbeiten kannst.", 4000);
              this.errorWhileLoading = true;
          }

          this.pathToInseratEdit = "/inserat/" + this.inserat.uuid + "/edit";

          if (this.inserat.automaticIncreaseTime){
            this.isAutomaticRenewal = this.inserat.automaticIncreaseTime;
            this.countOfDays = this.inserat.addDays ? this.inserat.addDays : 0;
          }

        })
        .catch(error => {
          this.errorWhileLoading = true;
          console.log(error);
        })
        .finally(() => {
          this.finishedLoading = true;
        });


  },
  methods: {
    getRoute() {
      return "/inserat/" + this.$route.params.id + "/edit";
    },
     isNumeric(str) {
      return !isNaN(str) && !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    removeAllLetters(){
       // reset to origin value if set
      if (this.inserat.automaticIncreaseTime){
        this.countOfDays = this.inserat.addDays;
      } else {
        this.countOfDays = '1';
      }
    },
    update(){

      // check if countOfDays is a number
      if (this.isAutomaticRenewal){
        if (!this.isNumeric(this.countOfDays)){
          this.removeAllLetters();
          this.openToast("Bitte nur ganze Zahlen in das Feld eintragen.", 5000);
          return;
        }
      }

      if (this.isAutomaticRenewal && (parseInt(this.countOfDays) < 1 || parseInt(this.countOfDays) > 10)) {
          this.openToast("Überprüfe deine Eingaben. Dein Wert darf nicht mehr als 10 sein und nicht weniger als 1.", 5000);
          return;
      }


      const inseratData = {
        'automaticIncreaseTime': this.isAutomaticRenewal,
        'addDays': parseInt(this.countOfDays),
        'fields': true
      };

      if (!this.isAutomaticRenewal){
          inseratData['addDays'] = null;
      }

      // patch inserat updates
      this.finishedUploading = false;
      axios.patch(process.env.VUE_APP_API_URL + '/inserats/' + this.inserat.uuid, inseratData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken,
          'Content-Type' : 'application/merge-patch+json',
        }
      })
          .then(response => {
            console.log(response);
            this.doneUploading = true;
          })
          .catch(error => {
            this.errorWhileCreating = true;
          }).finally(() => {
            this.finishedUploading = true;
            //this.$router.push({ path: this.pathToInseratEdit});
      });

    },
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    }
  }
});

</script>

<style scoped>

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.sideMargins {
  margin-left: 25px;
  margin-right: 25px;
}

.topMargins {
  margin-top: 10%;
}

.imageCircle {
  border: 2px solid #1dc0c6;
}

.imageCircleDenied {
  border: 2px solid #c61d53;
}

.imageCircleAccepted {
  border: 2px solid #1dc620;
}

.rounded-image {
  border-radius: 50%;
}

.spinnerCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.upload-btn-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  width: 100%;
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

</style>